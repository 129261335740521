<template>
  <div>
    <v-card class="mx-auto elevation-0">
      <v-card-title>
        <v-toolbar
          flat
          v-bind="utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.fundo"
        >
          <v-icon
            left
            v-bind="utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.icone"
            >{{
              utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.icone.nome
            }}</v-icon
          >
          <v-toolbar-title
            v-bind="utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.titulo"
            >{{ lang("perfil") }}</v-toolbar-title
          >
          <v-divider class="mx-4" inset horizontal></v-divider>
          <div class="flex-grow-2"></div>
          <v-btn
            v-on:click="abrirJanelaFiltro()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.pesquisar.atalho"
            v-on:shortkey="
              !desabilitado && permissaoListar() ? abrirJanelaFiltro() : null
            "
            v-bind="utilImp.cnt.CONFIG.btn.pesquisar"
            v-bind:disabled="desabilitado || !permissaoListar()"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.pesquisar.icone }}</v-icon
            >{{ lang("pesquisar") }}</v-btn
          >
          <v-btn
            v-on:click="modoIncluir()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.incluir.atalho"
            v-on:shortkey="
              !desabilitado && permissaoIncluir() ? modoIncluir() : null
            "
            v-bind="utilImp.cnt.CONFIG.btn.incluir"
            v-bind:disabled="desabilitado || !permissaoIncluir()"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.incluir.icone }}</v-icon
            >{{ lang("incluir") }}</v-btn
          >
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <!-- Listar -->
        <v-data-table
          class="elevation-0"
          height="70vh"
          hide-default-footer
          v-bind:headers="titulo"
          v-bind:items="arrayPerfil.result"
          v-bind:items-per-page="itemPagina"
        >
          <!-- Campo -->

          <!-- Acao Extra -->
          <template v-slot:item.acoes="{ item }">
            <v-icon
              light
              class="mr-2"
              v-bind:title="lang('alterar')"
              v-on:click="modoAlterar(item)"
              v-bind:disabled="desabilitado || !permissaoAlterar()"
              >{{
                utilImp.cnt.CONFIG.icon.alterar +
                  " " +
                  utilImp.cnt.CONFIG.icon.tamanho.grande
              }}</v-icon
            >
            <v-icon
              light
              class="mr-2"
              v-bind:title="lang('excluir')"
              v-on:click="modoExcluir(item)"
              v-bind:disabled="desabilitado || !permissaoExcluir()"
              >{{
                utilImp.cnt.CONFIG.icon.excluir +
                  " " +
                  utilImp.cnt.CONFIG.icon.tamanho.grande
              }}</v-icon
            >
            <!-- <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ on }">
                <v-icon
                  light
                  class="mr-2"
                  v-on="on"
                  v-bind:style="{ cursor: 'pointer' }"
                  v-bind:title="lang('maisAcoes') + '...'"
                  v-bind:disabled="desabilitado || !permissaoAcao()"
                  >{{
                    utilImp.cnt.CONFIG.icon.acaoExtra +
                      " " +
                      utilImp.cnt.CONFIG.icon.tamanho.grande
                  }}</v-icon
                >
              </template>
              <v-list>
                <v-list-item
                  v-for="(itemAcaoExtra, index) in menuAcaoExtra"
                  v-bind:key="index"
                  v-on:click="acaoExtra(item.idPerfil, itemAcaoExtra.value)"
                  v-bind:disabled="desabilitado || itemAcaoExtra.disabled"
                >
                  <v-list-item-title>{{
                    itemAcaoExtra.title
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->
          </template>
          <template v-slot:no-data>
            <v-alert v-bind:value="false" color="white" icon="info">{{
              lang("nenhumRegistroEncontrado")
            }}</v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-pagination
          v-model="page"
          v-bind="paginacao"
          v-bind:disabled="desabilitado || !permissaoListar()"
          v-on:input="pesquisar()"
        ></v-pagination>
        <v-spacer />
      </v-card-actions>
    </v-card>
    <!-- Pesquisar -->
    <v-dialog
      scrollable
      persistent
      v-model="dlgFiltrar"
      v-on:keydown.esc="validarFecharJanelaFiltro()"
      v-bind:max-width="
        utilImp.cnt.CONFIG.tema.janela.tamanho.largura.maxima.filtro
      "
    >
      <v-card>
        <v-card-title v-bind="utilImp.cnt.CONFIG.tema.janela.barraSuperior">
          <span class="headline">{{ lang("pesquisar") }}</span>
          <v-divider class="mx-4" inset horizontal></v-divider>
          <v-btn icon v-on:click="validarFecharJanelaFiltro()">
            <v-icon v-bind="utilImp.cnt.CONFIG.tema.janela.icone">{{
              utilImp.cnt.CONFIG.icon.fechar
            }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  name="namFiltroPerfil"
                  ref="refFiltroPerfil"
                  maxlength="30"
                  v-model="model.filtroPerfil"
                  v-bind:label="lang('perfil')"
                  v-bind="utilImp.cnt.CONFIG.textfield"
                  v-on:keyup.enter="pesquisar()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" sm="10" md="10" />
              <v-col cols="2" sm="2" md="2">
                <v-select
                  style="max-width:100%"
                  v-model="qtdItemPagina"
                  v-bind:items="arrayItemPagina"
                  v-bind:label="lang('itensPorPagina')"
                  v-bind="utilImp.cnt.CONFIG.select"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions v-bind="utilImp.cnt.CONFIG.tema.janela.rodape">
          <v-btn
            v-on:click="limparFiltro()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.limpar.atalho"
            v-on:shortkey="limparFiltro()"
            v-bind="utilImp.cnt.CONFIG.btn.limpar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.limpar.icone }}</v-icon
            >{{ lang("limpar") }}</v-btn
          >
          <div class="flex-grow-1"></div>
          <v-btn
            v-on:click="validarFecharJanelaFiltro()"
            v-bind="utilImp.cnt.CONFIG.btn.cancelar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.cancelar.icone }}</v-icon
            >{{ lang("cancelar") }}</v-btn
          >
          <v-btn
            v-on:click="confirmar()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.confirmar.atalho"
            v-on:shortkey="dlgFiltrar === true ? confirmar() : null"
            v-bind="utilImp.cnt.CONFIG.btn.confirmar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.confirmar.icone }}</v-icon
            >{{ lang("confirmar") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Salvar -->
    <v-dialog
      scrollable
      persistent
      v-model="dlgSalvar"
      v-bind:max-width="
        utilImp.cnt.CONFIG.tema.janela.tamanho.largura.maxima.salvar
      "
    >
      <v-card>
        <v-card-title v-bind="utilImp.cnt.CONFIG.tema.janela.barraSuperior">
          <span class="headline">{{ cmpTituloAcao }} {{ lang("perfil") }}</span>
          <v-divider class="mx-4" inset horizontal></v-divider>
          <v-btn icon v-on:click="dlgSalvar = false">
            <v-icon v-bind="utilImp.cnt.CONFIG.tema.janela.icone">{{
              utilImp.cnt.CONFIG.icon.fechar
            }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  name="namPerfil"
                  ref="refPerfil"
                  maxlength="50"
                  v-model="itemEditado.perfil"
                  v-bind:label="lang('perfil') + utilImp.cnt.CAMPO.obrigatorio"
                  v-bind="utilImp.cnt.CONFIG.textfield"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  name="namAnotacao"
                  ref="refAnotacao"
                  maxlength="200"
                  rows="3"
                  auto-grow
                  v-model="itemEditado.anotacao"
                  v-bind:label="lang('anotacao')"
                  v-bind="utilImp.cnt.CONFIG.textarea"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <!-- Permissao -->
            <v-data-table
              class="elevation-0"
              height="300px"
              fixed-header
              hide-default-footer
              v-bind:headers="tituloPermissao"
              v-bind:items="itemEditado.listPermissaoBEA"
              v-bind:items-per-page="itemPagina"
            >
              <!-- Campo -->
              <template v-slot:item.selecionado="{ item }">
                <v-checkbox
                  primary
                  hide-details
                  false-value="0"
                  true-value="1"
                  v-model="item.selecionado"
                  v-bind="utilImp.cnt.CONFIG.checkbox"
                ></v-checkbox>
              </template>
              <template v-slot:no-data>
                <v-alert v-bind:value="false" color="white" icon="info">{{
                  lang("nenhumRegistroEncontrado")
                }}</v-alert>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions v-bind="utilImp.cnt.CONFIG.tema.janela.rodape">
          <div class="flex-grow-1"></div>
          <v-btn
            v-on:click="fecharJanelaSalvar(false)"
            v-bind:disabled="desabilitado"
            v-bind="utilImp.cnt.CONFIG.btn.cancelar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.cancelar.icone }}</v-icon
            >{{ lang("cancelar") }}</v-btn
          >
          <v-btn
            v-on:click="salvar()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.salvar.atalho"
            v-on:shortkey="dlgSalvar === true ? salvar() : null"
            v-bind:disabled="desabilitado"
            v-bind="utilImp.cnt.CONFIG.btn.salvar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.salvar.icone }}</v-icon
            >{{ lang("salvar") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import util from "../../../../util/Util.js";

const ACAO_INCLUIR = "acaoIncluir";
const ACAO_ALTERAR = "acaoAlterar";
const ACAO_LISTAR = "acaoListar";
const ACAO_FECHAR_JANELA_FILTRO = "acaoFecharJanelaFiltro";
const ACAO_EXTRA_EXIBIR_PERMISSAO = "acaoExtraExibirPermissao";
const ITEM_PAGINA = 5000; // v-data-table
const ARRAY_ITEM_PAGINA = util.cnt.ARRAY.itemPagina1.qtd;
const POSICAO_ITEM_PAGINA = util.cnt.ARRAY.itemPagina1.posicao.p1;

export default {
  components: {},
  mixins: [util.mixUtil, util.mixAlerta],

  data: () => ({
    utilImp: util,
    dlgFiltrar: false,
    dlgSalvar: false,
    titulo: [],
    tituloPermissao: [],
    arrayPerfil: [],
    menuAcaoExtra: [],
    desabilitado: false,
    page: 1,
    itemPagina: ITEM_PAGINA,
    arrayItemPagina: ARRAY_ITEM_PAGINA,
    qtdItemPagina: ARRAY_ITEM_PAGINA[POSICAO_ITEM_PAGINA - 1],
    paginacao: { length: 1, totalVisible: 5, disabled: false },
    model: { filtroPerfil: "" },
    validacao: {
      filtroPerfil: "max:30",
      perfil: "required|max:50",
      anotacao: "max:200"
    },
    indice: -1,
    itemEditado: {
      idPerfil: null,
      oculto: "",
      perfil: "",
      anotacao: "",
      listPermissaoBEA: [
        {
          idPermissao: null,
          oculto: "",
          chave: "",
          anotacao: "",
          selecionado: ""
        }
      ]
    },
    itemPadrao: {
      idPerfil: null,
      oculto: util.cnt.BOLEANO.nao,
      perfil: "",
      anotacao: "",
      listPermissaoBEA: [
        {
          idPermissao: null,
          oculto: "",
          chave: "",
          anotacao: "",
          selecionado: "0"
        }
      ]
    }
  }),

  computed: {
    cmpTituloAcao() {
      return this.indice === -1 ? this.lang("incluir") : this.lang("alterar");
    }
  },

  watch: {},

  created() {
    this.iniciar();
  },

  mounted() {
    this.pesquisar();
    this.listarPermissao();
  },

  methods: {
    iniciar() {
      this.titulo = [
        // align: 'start' | 'center' | 'end'
        {
          text: this.lang("perfil"),
          align: "start",
          value: "perfil",
          sortable: true
        },
        {
          text: this.lang("anotacao"),
          align: "start",
          value: "anotacao",
          sortable: false
        },
        {
          text: this.lang("acoes"),
          align: "center",
          value: "acoes",
          sortable: false
        }
      ];
      // Títulos permissao
      this.tituloPermissao = [
        {
          text: this.lang("selecao"),
          align: "left",
          value: "selecionado",
          sortable: false
        },
        {
          text: this.lang("id"),
          align: "left",
          value: "idPermissao",
          sortable: true
        },
        {
          text: this.lang("chave"),
          align: "left",
          value: "chave",
          sortable: true
        },
        {
          text: this.lang("descricao"),
          align: "left",
          value: "anotacao",
          sortable: false
        }
      ];
    },

    modoIncluir() {
      for (var j = 0; j < this.itemPadrao.listPermissaoBEA.length; j++) {
        this.itemPadrao.listPermissaoBEA[j].selecionado = util.cnt.BOLEANO.nao;
      }
      this.indice = -1;
      this.itemEditado = Object.assign({}, this.itemPadrao);
      this.abrirJanelaSalvar();
      this.setFocusPerfil();
    },

    modoAlterar(item) {
      this.indice = this.arrayPerfil.result.indexOf(item);
      this.itemEditado = Object.assign({}, item);
      this.abrirJanelaSalvar();
      this.setFocusPerfil();
    },

    modoExcluir(item) {
      this.confirmDelete(this.lang("excluirPerfil"), item.perfil).then(
        result => {
          if (result.value) {
            this.excluir(item);
          }
        }
      );
    },

    salvar() {
      let acao = "";
      if (this.indice > -1) {
        // Alterar
        acao = ACAO_ALTERAR;
      } else {
        // Incluir
        acao = ACAO_INCLUIR;
      }
      this.validarSalvar(acao);
    },

    async validarSalvar(acao) {
      let result = "";
      result = await util.val.validate(
        this.itemEditado.perfil,
        this.validacao.perfil
      );
      if (!result.valid) {
        this.alertWarningToast(util.val.message(result.errors, this.lang("perfil")));
        return;
      }
      result = await util.val.validate(
        this.itemEditado.anotacao,
        this.validacao.anotacao
      );
      if (!result.valid) {
        this.alertWarningToast(
          util.val.message(result.errors, this.lang("anotacao"))
        );
        return;
      }
      // Validar permissões selecionadas
      let flagPermissaoSelecionada = false;
      for (var i = 0; i < this.itemEditado.listPermissaoBEA.length; i++) {
        if (this.itemEditado.listPermissaoBEA[i].selecionado === "1") {
          flagPermissaoSelecionada = true;
        }
      }
      if (flagPermissaoSelecionada === false) {
        this.alertWarningToast(this.lang("selecioneUmaPermissao"));
        return;
      }
      // Direcionar para inclusão
      if (acao === ACAO_INCLUIR) {
        this.incluir();
      }
      // Direcionar para alteração
      if (acao === ACAO_ALTERAR) {
        this.alterar();
      }
    },

    incluir() {
      this.desabilitar(true);
      util.srv.perfil
        .incluirPerfil(this.itemEditado)
        .then(response => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.limparItemEditado();
            this.fecharJanelaSalvar(true);
            this.pesquisar();
            this.alertSucessToast(this.lang("sucesso"));
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch(error => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },

    alterar() {
      this.desabilitar(true);
      util.srv.perfil
        .alterarPerfil(this.itemEditado)
        .then(response => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.limparItemEditado();
            this.fecharJanelaSalvar(true);
            this.pesquisar();
            this.alertSucessToast(this.lang("sucesso"));
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch(error => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },

    excluir(item) {
      this.desabilitar(true);
      util.srv.perfil
        .excluirPerfil(item.idPerfil)
        .then(response => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.pesquisar();
            this.alertSucess(this.lang("sucesso"));
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch(error => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },

    confirmar() {
      this.limparPaginador();
      this.pesquisar();
    },

    pesquisar() {
      if (this.dlgFiltrar) {
        // Caso a janela filtrar esteja aberta, ocorrera a validacao.
        this.validarPesquisar(ACAO_LISTAR);
      } else {
        this.listar();
      }
    },

    async validarPesquisar(acao) {
      let result = "";
      result = await util.val.validate(
        this.model.filtroPerfil,
        this.validacao.filtroPerfil
      );
      if (!result.valid) {
        this.alertWarningToast(util.val.message(result.errors, this.lang("perfil")));
        return;
      }
      // Direcionar para listar
      if (acao === ACAO_LISTAR) {
        this.listar();
      }
      // Direcionar para fechar a janela filtro
      if (acao === ACAO_FECHAR_JANELA_FILTRO) {
        this.fecharJanelaFiltro();
      }
    },

    listar() {
      this.desabilitar(true);
      this.limparLista();
      this.showLoader();
      let oculto = util.cnt.BOLEANO.nao;
      util.srv.perfil
        .listarPerfil(
          this.model.filtroPerfil,
          oculto,
          this.pagina(),
          this.limite()
        )
        .then(response => {
          this.distribuir(response.data);
        })
        .catch(error => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
          this.fecharJanelaFiltro();
          this.hideLoader();
        });
    },

    distribuir(arrayRegistro) {
      if (arrayRegistro.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
        if (arrayRegistro.result !== null) {
          this.arrayPerfil = arrayRegistro;
          this.paginador();
        } else {
          // this.alertInfo(this.lang("nenhumRegistroEncontrado"));
        }
      } else {
        this.alertWarning(this.langResultCode(arrayRegistro.resultCode));
      }
    },

    // Lista completa e limpa de permissões para a inclusão de um novo perfil
    listarPermissao() {
      this.desabilitar(true);
      this.limparListaPermissao();
      util.srv.permissao
        .listarPermissao()
        .then(response => {
          this.distribuirPermissao(response.data);
        })
        .catch(error => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },
    distribuirPermissao(arrayRegistro) {
      this.itemPadrao.listPermissaoBEA = arrayRegistro.result;
    },

    acaoExtra(idPerfil, opcao) {
      if (opcao === ACAO_EXTRA_EXIBIR_PERMISSAO) {
        // this.verificarDataHoraLocal(idPerfil);
      }
    },

    // verificarDataHoraLocal(idPerfil) {
    //   this.desabilitar(true);
    //   util.srv.perfil
    //     .verificarHorario(idPerfil)
    //     .then(response => {
    //       if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
    //         this.alertInfo(response.data.result);
    //       } else {
    //         this.alertWarning(this.langResultCode(response.data.resultCode));
    //       }
    //     })
    //     .catch(error => {
    //       this.alertError(this.lang("erroOperacao"));
    //       console.log(error);
    //     })
    //     .finally(() => {
    //       this.desabilitar(false);
    //     });
    // },

    abrirJanelaFiltro() {
      this.dlgFiltrar = true;
      this.setFocusFiltroPerfil();
    },
    abrirJanelaSalvar() {
      this.dlgSalvar = true;
    },

    fecharJanelaFiltro() {
      if (!this.desabilitado) {
        this.dlgFiltrar = false;
      }
    },
    fecharJanelaSalvar(passeLivre) {
      if (!passeLivre) {
        if (!this.desabilitado) {
          this.dlgSalvar = false;
        }
      } else {
        this.dlgSalvar = false;
      }
    },
    /* Metodo responsavel por fechar a janela de pesquisa somente se os filtros forem validos */
    /* Necessario para que o paginador não execute a consulta com parametros invalidos */
    validarFecharJanelaFiltro() {
      this.validarPesquisar(ACAO_FECHAR_JANELA_FILTRO);
    },

    limparLista() {
      this.arrayPerfil = [];
    },
    limparListaPermissao() {
      this.itemPadrao.listPermissaoBEA = [];
    },
    limparFiltro() {
      this.model.filtroPerfil = "";
      this.setFocusFiltroPerfil();
    },
    limparItemEditado() {
      this.itemEditado = Object.assign({}, this.itemPadrao);
    },
    limparPaginador() {
      this.page = 1;
      this.paginacao.length = 1;
    },

    desabilitar(desabilitado) {
      this.desabilitado = desabilitado;
    },

    setFocusFiltroPerfil() {
      setTimeout(() => {
        this.$refs.refFiltroPerfil.focus();
      }, util.cnt.CONFIG.timer.focus);
    },

    setFocusPerfil() {
      setTimeout(() => {
        this.$refs.refPerfil.focus();
      }, util.cnt.CONFIG.timer.focus);
    },

    paginador() {
      let contRegistro = this.arrayPerfil.result.length;
      if (contRegistro >= this.qtdItemPagina) {
        this.paginacao.length = this.page + 1;
      }
    },

    pagina: function() {
      return this.page - 1;
    },

    limite: function() {
      return this.qtdItemPagina;
    },

    permissaoListar() {
      return this.verificarPermissao(util.prm.P_1011);
    },
    permissaoIncluir() {
      return this.verificarPermissao(util.prm.P_1012);
    },
    permissaoAlterar() {
      return this.verificarPermissao(util.prm.P_1013);
    },
    permissaoExcluir() {
      return this.verificarPermissao(util.prm.P_1014);
    },
    permissaoAcao() {
      return this.verificarPermissao(util.prm.P_1015);
    }
  }
};
</script>
